import { Component, OnInit } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { Occupation } from './occupation.model';

/**
 * Controller used to view all Occupation
 * 
 * @author Floyd Kissoon
 * @since 20180703
 */
 @Component({
     selector: 'occupation-view-all',
     templateUrl: './occupation-view-all.html'
 })
export class OccupationViewAllComponent implements OnInit
{
    private occupations: Occupation[];
    private filters: any;
    private isWorking = false;

    constructor(private dataManager: DataManager)
    {
        this.filters = {};
        this.filters.sort_by = "name|ASC";
        this.filters.num_items = "25";
        this.filters.name = "";
    }
    
    ngOnInit()
    {
        this.loadData();
    }
    
    loadData()
    {
        this.isWorking = true;
        this.dataManager.GET("api/occupation", this.filters).then(
        res => 
        {
            this.occupations = res.items;
            this.isWorking = false; 
        });
    }
    
}