import { Component, Output, EventEmitter } from '@angular/core';

import { DataManager } from "techlify-ng";
import { AlertService } from "techlify-ng";
import { RequestErrorService } from "techlify-ng";

import { HealthIssue } from "./health-issue.model";

/**
 * Controller that adds a new health Issue to the system
 * 
 * @author Floyd Kissoon
 * @since 20180613
 */
@Component({
    selector: "health-issue-add",
    templateUrl: "./health-issue-form.html"
})
export class HealthIssueAddComponent
{

    @Output() onSave = new EventEmitter<HealthIssue>();

    private healthIssue = new HealthIssue();
    private isWorking = false;
    
    constructor(private dataManager: DataManager, private alertService: AlertService,
    private errorHandler: RequestErrorService) {}
    
    save()
    {
        this.isWorking = true;
        this.dataManager.POST("api/health/issue", this.healthIssue).then(
            res =>
            {
                this.onSave.emit(res.healthIssue);
                this.alertService.addAlert(res.message, "success");
                this.isWorking = false;
            },
            error =>
            {
                this.errorHandler.handleError(error);
                this.isWorking = false;
            }
        );
    }
}