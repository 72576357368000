import { Component, Input, Output, EventEmitter } from '@angular/core';

import { DataManager } from "techlify-ng";
import { AlertService } from "techlify-ng";
import { RequestErrorService } from "techlify-ng";

import { Organization } from "./organization.model";

/**
 * Controller used to delete a Place Of Employment
 * 
 * @author Floyd Kissoon
 * @since 20180703
 */
@Component({
    selector: 'organization-delete',
    templateUrl: "./organization-delete.html"
})
export class OrganizationDeleteComponent
{
    @Input() organization: Organization;
    @Output() onSave = new EventEmitter<Organization>();
    private isWorking = false;
    
    constructor(private dataManager: DataManager, private alertService: AlertService,
    private errorHandler: RequestErrorService) { }

    deleteOrganization()
    {
        this.isWorking = true;
        this.dataManager.DELETE("api/organization/" + this.organization.id).then(
            res =>
            {
                this.onSave.emit(this.organization);
                this.alertService.addAlert(res.message, "success");
                this.isWorking = false;
            },
            error =>
            {
                this.errorHandler.handleError(error);
                this.isWorking = false;
            }
        );
    }
}