import { Component, Output, EventEmitter, Input } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { AlertService } from 'techlify-ng';
import { RequestErrorService } from 'techlify-ng';

import { SchoolInformation } from "./school-information.model";
import { Client } from '../client/client.model';

/**
 * Controller that adds a new record
 * 
 * @author Floyd Kissoon
 * @since 20190729
 */
@Component({
    selector: 'school-information-add',
    templateUrl: './school-information-form.html'
})
export class SchoolInformationAddComponent {
    @Input() client: Client;
    @Output() onSave = new EventEmitter<SchoolInformation>();
    private schoolInfo: SchoolInformation;

    private isWorking = false;

    constructor(private dataManager: DataManager,
        private alertService: AlertService,
        private errorHandler: RequestErrorService) { }

    initForm() {
        this.schoolInfo = new SchoolInformation();
        this.schoolInfo.client_id = this.client.id;
    }

    ngOnInit() {
        this.initForm();
    }

    save() {
        this.isWorking = true;
        this.schoolInfo.client_id = this.client.id;
        this.dataManager.POST('api/school-information', this.schoolInfo).then(
            res => {
                this.onSave.emit(res.item);
                this.alertService.addAlert('Successfully added school information', 'success');
                this.isWorking = false;
                this.initForm();
            },
            error => {
                this.errorHandler.handleError(error);
                this.isWorking = false;
            }
        );
    }
}