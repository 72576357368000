import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

/* External Components */
import { GrowlModule } from 'primeng/primeng';
import { DialogModule } from 'primeng/primeng';

/* Routing Provider */
import { SchoolRoutingProvider } from './school.routes';

/* Core Services */
import { UtilityModule } from "../../utility/utility.module";

import { SchoolViewAllComponent } from "./school-view-all.component";
import { SchoolAddComponent } from "./school-add.component";
import { SchoolEditComponent } from "./school-edit.component";
import { SchoolDeleteComponent } from "./school-delete.component";

@NgModule({
    imports: [
        BrowserModule, FormsModule,
        DialogModule,
        GrowlModule,
        BrowserAnimationsModule,
        SchoolRoutingProvider,
        UtilityModule
    ],
    declarations: [
        SchoolViewAllComponent,
         SchoolAddComponent,
         SchoolEditComponent,
         SchoolDeleteComponent
    ],
    providers: [,],
    exports: []
})
export class SchoolModule { }
