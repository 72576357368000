import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { OccupationDataService } from "./occupation-data.service";
import { Occupation } from "./occupation.model";

@Component({
    selector: "occupation-selector",
    templateUrl: "./occupation-selector.html"
})
export class OccupationSelectComponent implements OnInit
{
    private occupations: Occupation[];

    @Input() id: number;
    @Output() idChange: EventEmitter<number>;

    constructor(private dataService: OccupationDataService)
    {
        this.occupations = new Array<Occupation>();
        this.idChange = new EventEmitter<number>();
    }

    ngOnInit()
    {
        this.loadData();
    }

    private loadData()
    {
        this.dataService.getOccupations().then(
            res =>
            {
                this.occupations = res.items;
            }
        );
    }

    itemSelected()
    {
        this.idChange.emit(this.id);
    }
    
    reloadData()
    {
        this.dataService.reloadData().then(
            res => 
            {
                this.occupations = res.items;
            }
        );
    }

}