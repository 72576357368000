import { Component, Input, Output, EventEmitter } from '@angular/core';

import { DataManager } from "techlify-ng";
import { AlertService } from "techlify-ng";
import { RequestErrorService } from "techlify-ng";

import { HealthIssue } from "./health-issue.model";

/**
 * Controller used to delete a health Issue
 * 
 * @author Floyd Kissoon
 * @since 20180613
 */
@Component({
    selector: 'health-issue-delete',
    templateUrl: "./health-issue-delete.html"
})
export class HealthIssueDeleteComponent
{
    @Input() healthIssue: HealthIssue;
    @Output() onSave = new EventEmitter<HealthIssue>();
    private isWorking = false;
    
    constructor(private dataManager: DataManager, private alertService: AlertService,
    private errorHandler: RequestErrorService) { }

    deleteHealthIssue()
    {
        this.isWorking = true;
        this.dataManager.DELETE("api/health/issue/" + this.healthIssue.id).then(
            res =>
            {
                this.onSave.emit(this.healthIssue);
                this.alertService.addAlert(res.message, "success");
                this.isWorking = false;
            },
            error =>
            {
                this.errorHandler.handleError(error);
                this.isWorking = false;
            }
        );
    }
}