import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

/* External Components */
import { GrowlModule } from 'primeng/primeng';
import { DialogModule } from 'primeng/primeng';

/* Routing Provider */
import { OrganizationRoutingProvider } from './organization.routes';

/* Core Services */
import { UtilityModule } from "techlify-ng";

import { OrganizationViewAllComponent } from "./organization-view-all.component";
import { OrganizationAddComponent } from "./organization-add.component";
import { OrganizationEditComponent } from "./organization-edit.component";
import { OrganizationDeleteComponent } from "./organization-delete.component";

import { OrganizationDataService } from "./organization-data.service";
import { OrganizationSelectComponent } from "./organization-selector.component";

@NgModule({
    imports: [
        BrowserModule, FormsModule,
        DialogModule,
        GrowlModule,
        BrowserAnimationsModule,
        OrganizationRoutingProvider, UtilityModule
    ],
    declarations: [
    OrganizationViewAllComponent, OrganizationAddComponent, OrganizationEditComponent, OrganizationDeleteComponent,
    OrganizationSelectComponent,
    ],
    providers: [OrganizationDataService],
    exports: [OrganizationSelectComponent]
})
export class OrganizationModule { }
