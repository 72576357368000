import { Component, OnInit, Input } from '@angular/core';

import { DataManager } from "techlify-ng";
import { RequestErrorService } from 'techlify-ng';

import { SchoolInformation } from './school-information.model';
import { Client } from '../client/client.model';

/**
 * Component used to view School Information
 * 
 * @author Floyd Kissoon
 * @since 20190729
 */
@Component({
    selector: "school-information-for-client",
    templateUrl: "./school-information-for-client.html"
})
export class SchoolInformationForClientComponent implements OnInit {
    @Input() client: Client;
    private filters: any;
    private schoolInfo: SchoolInformation[];

    constructor(private dataManager: DataManager,
        private errorHandler: RequestErrorService) {
    }

    ngOnInit() {
        this.loadData();
    }

    loadData() {

        this.dataManager.GET('api/school-information/' + this.client.id).then(
            res => {
                this.schoolInfo = res.item;
            },
            error => {
                this.errorHandler.handleError(error);
            }
        );
    }

}
