import { Component, OnInit } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { HealthIssue } from './health-issue.model';

/**
 * Controller used to view all health Issues
 * 
 * @author Floyd Kissoon
 * @since 20180613
 */
 @Component({
     selector: 'health-issue-view-all',
     templateUrl: './health-issue-view-all.html'
 })
export class HealthIssueViewAllComponent implements OnInit
{
    private healthIssues: HealthIssue[];
    private filters: any;
    private isWorking = false;

    constructor(private dataManager: DataManager)
    {
        this.filters = {};
        this.filters.sort_by = "";
        this.filters.num_items = "25";
        this.filters.name = "";
    }
    
    ngOnInit()
    {
        this.loadData();
    }
    
    loadData()
    {
        this.isWorking = true;
        this.dataManager.GET("api/health/issue", this.filters).then(
        res => 
        {
            this.healthIssues = res.items; 
            this.isWorking = false;
        });
    }
    
}