import { Component, Output, EventEmitter } from '@angular/core';

import { DataManager } from "techlify-ng";
import { AlertService } from "techlify-ng";
import { RequestErrorService } from "techlify-ng";

import { School } from "./school.model";

/**
 * Controller that adds a new School to the system
 * 
 * @author Floyd Kissoon
 * @since 20180613
 */
@Component({
    selector: "school-add",
    templateUrl: "./school-form.html"
})
export class SchoolAddComponent
{

    @Output() onSave = new EventEmitter<School>();

    private school = new School();

    constructor(private dataManager: DataManager, private alertService: AlertService,
                private errorHandler: RequestErrorService) {}
    
    save()
    {
        this.dataManager.POST("api/school", this.school).then(
            res =>
            {
                this.onSave.emit(res.school);
                this.alertService.addAlert(res.message, "success");
            },
            error =>
            {
                this.errorHandler.handleError(error);
            }
        );
    }
}