import { Component, Input, Output, EventEmitter } from '@angular/core';

import { DataManager } from "techlify-ng";
import { AlertService } from "techlify-ng";
import { RequestErrorService } from "techlify-ng";

import { Occupation } from "./occupation.model";

/**
 * Controller used to delete a Occupation
 * 
 * @author Floyd Kissoon
 * @since 20180703
 */
@Component({
    selector: 'occupation-delete',
    templateUrl: "./occupation-delete.html"
})
export class OccupationDeleteComponent
{
    @Input() occupation: Occupation;
    @Output() onSave = new EventEmitter<Occupation>();
    private isWorking = false;
    
    constructor(private dataManager: DataManager, private alertService: AlertService,
    private errorHandler: RequestErrorService) { }

    deleteOccupation()
    {
        this.isWorking = true;
        this.dataManager.DELETE("api/occupation/" + this.occupation.id).then(
            res =>
            {
                this.onSave.emit(this.occupation);
                this.alertService.addAlert(res.message, "success");
                this.isWorking = false;
            },
            error =>
            {
                this.errorHandler.handleError(error);
                this.isWorking = false;
            }
        );
    }
}