import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

/* External Components */
import { GrowlModule } from 'primeng/primeng';
import { DialogModule } from 'primeng/primeng';

/* Selectors */
import { NumItemsSelector } from './numItemsSelector.component';
import {DateUtils} from './DateUtils.service';
import { NumberSelector } from './number-selector.component';

@NgModule({
    imports: [
        BrowserModule, FormsModule,
        DialogModule,
        GrowlModule,
        BrowserAnimationsModule,
    ],
    declarations: [
    NumItemsSelector, NumberSelector,
     ],
    providers: [DateUtils],
    exports: [NumItemsSelector, NumberSelector]
})
export class UtilityModule { }
