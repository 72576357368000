/**
 * Class representation of a School
 * 
 * @author Floyd Kissoon
 * @since 20180613
 */
export class School
{
    id: number;
    name: string;
    description: string;
    user_id: number;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
}
