import { Component, Input, Output, EventEmitter } from '@angular/core';

import { DataManager } from "techlify-ng";
import { AlertService } from "techlify-ng";
import { RequestErrorService } from "techlify-ng";

import { HealthIssue } from "./health-issue.model";

/**
 * Controller used to edit a Health Issue
 * 
 * @author Floyd Kissoon
 * @since 20180613
 */
@Component({
    selector: 'health-issue-edit',
    templateUrl: "./health-issue-form.html"
})
export class HealthIssueEditComponent
{

    @Input() healthIssue: HealthIssue;
    @Output() onSave = new EventEmitter<HealthIssue>();
    private isWorking = false;
    
    constructor(private dataManager: DataManager, private alertService: AlertService,
     private errorHandler: RequestErrorService) { }

    save()
    {
        this.isWorking = true;
        this.dataManager.PUT("api/health/issue/" + this.healthIssue.id, this.healthIssue).then(
            res =>
            {
                this.onSave.emit(res.healthIssue);
                this.alertService.addAlert(res.message, "success");
                this.isWorking = false;
            },
            error =>
            {
                this.errorHandler.handleError(error);
                this.isWorking = false;
            }
        );
    }
}