import { Component, Input, Output, EventEmitter } from '@angular/core';

import { DataManager } from "techlify-ng";
import { AlertService } from "techlify-ng";
import { RequestErrorService } from "techlify-ng";

import { School } from "./school.model";

/**
 * Controller used to edit a School
 * 
 * @author Floyd Kissoon
 * @since 20180613
 */
@Component({
    selector: 'school-edit',
    templateUrl: "./school-form.html"
})
export class SchoolEditComponent
{

    @Input() school: School;
    @Output() onSave = new EventEmitter<School>();

    constructor(private dataManager: DataManager, private alertService: AlertService,
     private errorHandler: RequestErrorService) { }

    save()
    {
        this.dataManager.PUT("api/school/" + this.school.id, this.school).then(
            res =>
            {
                this.onSave.emit(res.school);
                this.alertService.addAlert(res.message, "success");
            },
            error =>
            {
                this.errorHandler.handleError(error);
            }
        );
    }
}