/**
 * Class representation of a School Information
 * 
 * @author Floyd Kissoon
 * @since 20190729
 */
export class SchoolInformation
{
    id: number;
    client_id: number;
    name: string;
    address: string;
    phone: string;
    email: string;
    logo: string;
    tin: string;
    motto: string;
    creator_id: number;
    created_at: Date;
    updated_at: Date;

    /* Relations */
    creator: any;
    client: any;

    constructor() {
        this.creator = {};
        this.client = {};
    }
}