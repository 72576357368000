import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

/* External Components */
import { GrowlModule } from 'primeng/primeng';
import { DialogModule } from 'primeng/primeng';

/* Routing Provider */
import { OccupationRoutingProvider } from './occupation.routes';

/* Core Services */
import { UtilityModule } from "techlify-ng";

import { OccupationViewAllComponent } from "./occupation-view-all.component";
import { OccupationAddComponent } from "./occupation-add.component";
import { OccupationEditComponent } from "./occupation-edit.component";
import { OccupationDeleteComponent } from "./occupation-delete.component";

import { OccupationDataService } from "./occupation-data.service";
import { OccupationSelectComponent } from "./occupation-selector.component";

@NgModule({
    imports: [
        BrowserModule, FormsModule,
        DialogModule,
        GrowlModule,
        BrowserAnimationsModule,
        OccupationRoutingProvider, UtilityModule
    ],
    declarations: [
        OccupationViewAllComponent, OccupationAddComponent, OccupationEditComponent, OccupationDeleteComponent,
        OccupationSelectComponent,
    ],
    providers: [OccupationDataService],
    exports: [OccupationSelectComponent, OccupationAddComponent]
})
export class OccupationModule { }
