import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

/* External Components */
import { GrowlModule } from 'primeng/primeng';
import { DialogModule } from 'primeng/primeng';

/* Routing Provider */
import { HealthIssueRoutingProvider } from './health-issue.routes';

/* Core Services */
import { UtilityModule } from "techlify-ng"

import { HealthIssueViewAllComponent } from "./health-issue-view-all.component";
import { HealthIssueAddComponent } from "./health-issue-add.component";
import { HealthIssueEditComponent } from "./health-issue-edit.component";
import { HealthIssueDeleteComponent } from "./health-issue-delete.component";

import { HealthIssueDataService } from "./health-issue-data.service";
import { HealthIssueSelectComponent } from "./health-issue-selector.component";

@NgModule({
    imports: [
        BrowserModule, FormsModule,
        DialogModule,
        GrowlModule,
        BrowserAnimationsModule,
        HealthIssueRoutingProvider, UtilityModule
    ],
    declarations: [
    HealthIssueViewAllComponent, HealthIssueAddComponent, HealthIssueEditComponent, HealthIssueDeleteComponent,
    HealthIssueSelectComponent,
    ],
    providers: [HealthIssueDataService,],
    exports: [HealthIssueSelectComponent]
})
export class HealthIssueModule { }
