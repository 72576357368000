/**
 * Class representation of Place of Employment
 * 
 * @author Floyd Kissoon
 * @since 20180703
 */
 export class Occupation
 {
     id: number;
     name: string;
     description: string;
     created_at: Date;
     updated_at: Date;
     deleted_at: Date;
 }
