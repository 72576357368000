import { Component, OnInit } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { Organization } from './organization.model';

/**
 * Controller used to view all Place Of Employment
 * 
 * @author Floyd Kissoon
 * @since 20180703
 */
@Component({
    selector: 'organization-view-all',
    templateUrl: './organization-view-all.html'
})
export class OrganizationViewAllComponent implements OnInit {
    private organizations: Organization[];
    private filters: any;
    private isWorking = false;

    constructor(private dataManager: DataManager) {
        this.filters = {};
        this.filters.sort_by = "name|ASC";
        this.filters.num_items = "25";
        this.filters.name = "";
    }

    ngOnInit() {
        this.loadData();
    }

    loadData() {
        this.isWorking = true;
        this.dataManager.GET("api/organization", this.filters).then(
            res => {
                this.organizations = res.items;
                this.isWorking = false;
            });
    }

}