import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { OrganizationDataService } from "./organization-data.service";
import { Organization } from "./organization.model";

@Component({
    selector: "organization-selector",
    templateUrl: "./organization-selector.html"
})
export class OrganizationSelectComponent implements OnInit
{
    private organizations: Organization[];

    @Input() id: number;
    @Output() idChange: EventEmitter<number>;

    constructor(private dataService: OrganizationDataService)
    {
        this.organizations = new Array<Organization>();
        this.idChange = new EventEmitter<number>();
    }

    ngOnInit()
    {
        this.loadData();
    }

    private loadData()
    {
        this.dataService.getOrganizations().then(
            res =>
            {
                this.organizations = res.items;
            }
        );
    }

    itemSelected()
    {
        this.idChange.emit(this.id);
    }
     
    reloadData()
    {
        this.dataService.reloadData().then(
            res => 
            {
                this.organizations = res.items;
            }
        );
    }

}