import { Injectable } from '@angular/core';

import { DataManager } from "techlify-ng";

/**
 * Service that provides Occupation Data
 * 
 * @author Floyd Kissoon
 * @since 20180703
 */
@Injectable()
export class OccupationDataService
{

    private data: any;

    constructor(private dataManager: DataManager)
    {

    }

    getOccupations()
    {
        var promise;
        if(undefined !== this.data)
        {
            promise = new Promise((resolve, reject) =>
            {
                resolve(this.data);
            });

            return promise;
        }

        promise = this.dataManager.GET("api/occupation", { sort_by: "name|ASC" });

        promise.then(
            res =>
            {
                this.data = res;
            }
        );

        return promise;
    }
    
    reloadData()
    {
        this.data = undefined;
        return this.getOccupations();
    }
}