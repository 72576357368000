import { Injectable } from '@angular/core';

/**
 * Service that provides utility functions for dates
 * 
 * @author Joshua Kissoon
 * @since 20170726
 */
@Injectable()
export class DateUtils
{

    /**
     * Takes in a date object and outputs the string value
     */
    getYYYYMMDDString(inputDate: Date): string
    {
        var year = inputDate.getFullYear();
        var month = (inputDate.getMonth() + 1) < 10 ? ("0" + (inputDate.getMonth() + 1)) : (inputDate.getMonth() + 1);
        var date = inputDate.getDate() < 10 ? ("0" + inputDate.getDate()) : inputDate.getDate();

        return year + "-" + month + "-" + date;
    }
    
    /**
     * Takes in a date object and outputs the year
     */
    getYYYY(inputDate: Date): number
    {
        var year = inputDate.getFullYear();
        return year;
    }
    
    /**
     * Takes in a date object and outputs the Month
     */
    getMM(inputDate: Date): number
    {
        var month =inputDate.getMonth() + 1;
        return month;
    }
//         var n = this.rfidCardUseManualEntry.manual_date.toLocaleTimeString();

    getDateTime(inputDate: Date): string
    {
        var year = inputDate.getFullYear();
        var month = (inputDate.getMonth() + 1) < 10 ? ("0" + (inputDate.getMonth() + 1)) : (inputDate.getMonth() + 1);
        var date = inputDate.getDate() < 10 ? ("0" + inputDate.getDate()) : inputDate.getDate();
        var time = inputDate.toLocaleTimeString();

        return year + "-" + month + "-" + date + " " + time;
    }

}