import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { HealthIssueDataService } from "./health-issue-data.service";
import { HealthIssue } from "./health-issue.model";

@Component({
    selector: "health-issue-selector",
    templateUrl: "./health-issue-selector.html"
})
export class HealthIssueSelectComponent implements OnInit
{
    private healthIssues: HealthIssue[];

    @Input() id: number;
    @Output() idChange: EventEmitter<number>;

    constructor(private dataService: HealthIssueDataService)
    {
        this.healthIssues = new Array<HealthIssue>();
        this.idChange = new EventEmitter<number>();
    }

    ngOnInit()
    {
        this.loadData();
    }

    private loadData()
    {
        this.dataService.getHealthIssues().then(
            res =>
            {
                this.healthIssues = res.items;
            }
        );
    }

    itemSelected()
    {
        this.idChange.emit(this.id);
    }

}