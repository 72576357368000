import { Component, OnInit } from '@angular/core';

import { DataManager } from 'techlify-ng';
import { School } from './school.model';

/**
 * Controller used to view all School
 * 
 * @author Floyd Kissoon
 * @since 20180613
 */
 @Component({
     selector: 'school-view-all',
     templateUrl: './school-view-all.html'
 })
export class SchoolViewAllComponent implements OnInit
{
    private schools: School[];
    private filters: any;

    constructor(private dataManager: DataManager)
    {
        this.filters = {};
        this.filters.sort_by = "";
        this.filters.num_items = "25";
        this.filters.name = "";
    }
    
    ngOnInit()
    {
        this.loadData();
    }
    
    loadData()
    {
        this.dataManager.GET("api/school", this.filters).then(
        res => 
        {
            this.schools = res.data; 
        });
    }
    
}