import { Component, Output, EventEmitter } from '@angular/core';

import { DataManager } from "techlify-ng";
import { AlertService } from "techlify-ng";
import { RequestErrorService } from "techlify-ng";

import { Organization } from "./organization.model";

/**
 * Controller that adds a new Place Of Employment to the system
 * 
 * @author Floyd Kissoon
 * @since 20180703
 */
@Component({
    selector: "organization-add",
    templateUrl: "./organization-form.html"
})
export class OrganizationAddComponent
{

    @Output() onSave = new EventEmitter<Organization>();

    private organization = new Organization();
    private isWorking = false;
    
    constructor(private dataManager: DataManager, private alertService: AlertService,
                private errorHandler: RequestErrorService) {}
    
    save()
    {
        this.isWorking = true;
        this.dataManager.POST("api/organization", this.organization).then(
            res =>
            {
                this.onSave.emit(res.organization);
                this.alertService.addAlert(res.message, "success");
                this.isWorking = false;
            },
            error =>
            {
                this.errorHandler.handleError(error);
                this.isWorking = false;
            }
        );
    }
}